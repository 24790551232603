export const TOUR_CATEGORIES = {
    MATH_FUNCTIONALITY: "Math functionality",
    INVENTORY_MANAGEMENT: "Inventory management",
    REPORT_GENERATOR: "Report generator"
  };
  
export const TOUR_ID = {
    VIRTUAL_DEVICE_TOUR: "what-is-a-virtual-device",
    VIRTUAL_VARIABLE_TOUR: "what-is-a-virtual-variable",
    MATH_EDITOR_TOUR: "what-is-a-math-editor",
    REPORT_GENERATOR_TOUR: "what-is-report-generator",
  };

export const FIRST_TOUR_STEP = 0;
export const RESET_TOUR_STEP = -1;