import {
  DXAlarm,
  DXCurrentValue,
  DXDevice,
  DXDeviceType,
  DXGraph24Data,
  DXIssue,
  DXVariable,
  DXVariableList,
  DXVariableListByIndex,
} from "@/types";
import {} from "../../types";
import { defineStore } from "pinia";
import { DXDeviceHandler } from "@/utils/devices/device";
import { formatDeviceType } from "@/utils/FormatDeviceType";

export const useDeviceStore = defineStore("device", {
  state: () => ({
    devicesList: [] as DXDeviceType[], // Devices which the user is allowed to add
    variables: [] as DXVariable[], // Variables
    devices: [] as DXDevice[], // Devices
    issues: {} as { [key: string]: DXIssue[] }, // Issues
    alarms: [] as DXAlarm[], // Alarms
    // Dashboard
    values: {} as { [key: string]: DXCurrentValue[] }, // current-values from all devices set within widgets
    graphs: {} as { [key: string]: DXGraph24Data }, // get-24h-statistics stored with device, sppindex, sppsubindex
    deviceInstances: {} as { [key: string]: DXDeviceHandler },
  }),
  getters: {
    getDeviceById:
      (state) =>
      (id: string): DXDevice | undefined => {
        const device = state.devices.filter((item) => item.id == parseInt(id));
        if (device) {
          return device[0];
        }
      },
    getOnlineStatusByDeviceId: (state) => (id: number | string | undefined) => {
      if (!id) return;
      const device = state.devices.find((item) => item.id == id);
      if (device) {
        return device.online;
      }
    },
    getVariablesByType(state) {
      const variablesList: DXVariableList = {};
      const variables = state.variables;
      if (variables && variables.length > 0) {
        variables.forEach((variable) => {
          if (Object.keys(variablesList).includes(variable.deviceType)) {
            variablesList[
              variable.deviceType as keyof typeof variablesList
            ].push(variable);
          } else {
            Object.assign(variablesList, {
              [variable.deviceType as keyof typeof variablesList]: [variable],
            });
          }
        });
      }
      return variablesList;
    },
    getVariablesByTypeAndIndex(state) {
      const variablesList: DXVariableListByIndex = {};
      const variables = state.variables;
      if (!variables || !variables.length) {
        return variablesList;
      }

      variables.forEach((variable) => {
        if (Object.keys(variablesList).includes(variable.deviceType)) {
          variablesList[variable.deviceType as keyof typeof variablesList][
            variable.index + "__" + variable.subindex
          ] = variable;
        } else {
          Object.assign(variablesList, {
            [variable.deviceType as keyof typeof variablesList]: {
              [variable.index + "__" + variable.subindex]: variable,
            },
          });
        }
      });
      return variablesList;
    },
    getVariablesGroupsByType: (state) => (type: string) => {
      const groups: DXVariableList = {};
      if (!state.variables) {
        return groups;
      }
      const previewableVariables = state.variables.filter(
        (variable) => formatDeviceType(variable.deviceType) === type && variable.showpreview
      );
      console.log("previewableVariables", previewableVariables);
      if (previewableVariables && previewableVariables.length) {
        previewableVariables.forEach((variable) => {
          if (Object.keys(groups).includes(variable.group)) {
            groups[variable.group as keyof typeof groups].push(variable);
          } else {
            Object.assign(groups, {
              [variable.group as keyof typeof groups]: [variable],
            });
          }
        });
      }
      const ordered = Object.keys(groups)
        .sort()
        .reduce((obj: DXVariableList, key) => {
          obj[key as keyof typeof groups] = groups[key];
          return obj;
        }, {});

      return ordered;
    },
    getDeviceTypeById: (state) => (id: number) => {
      if (id && state.devices && state.devices.length > 0) {
        return state.devices.filter((device) => device.id === id)[0];
      }
      return undefined;
    },
    getDeviceIdBySn: (state) => (sn: string) => {
      if (sn && state.devices) {
        return state.devices.find((device) => device.sn == sn);
      }
      return false;
    },
    getTags(state) {
      if (state.devices) {
        const tags = new Set<string>();
        state.devices
          .map((d) => (d.tags.length > 0 ? d.tags : ["Default"]))
          .forEach((ts) => ts.forEach((t) => tags.add(t)));
        return Array.from(tags);
      }
      return [];
    },
    getIssuesByDevice(state) {
      const alarms = state.alarms;
      const issues = state.issues;
      const devices = {} as Record<
        number,
        {
          fault?: DXIssueOverview[];
          warning?: DXIssueOverview[];
          alarm?: DXAlarmOverview[];
        }
      >;
      if (Array.isArray(alarms) && alarms.length > 0) {
        for (let i = 0; i < alarms.length; i++) {
          const alarm = alarms[i];
          if (alarm.alarming > 0) {
            if (devices[alarm.deviceid] === undefined) {
              devices[alarm.deviceid] = { alarm: [] };
            }
            devices[alarm.deviceid].alarm?.push(alarmToOverview(alarm));
          }
        }
      }
      if (issues) {
        Object.keys(issues).forEach((key) => {
          issues[key].forEach((issue) => {
            if (issue.device.toString() == key) {
              if (issue.tsEnd < 1) {
                if (typeof devices[issue.device] === "undefined") {
                  devices[issue.device] = { fault: [], warning: [] };
                }
                if (typeof devices[issue.device][issue.type] === "undefined") {
                  devices[issue.device][issue.type] = [];
                }
                devices[issue.device][issue.type]?.push(issueToOverview(issue));
              }
            }
          });
        });
      }
      return devices;
    },
    getIssuesByVariable:
      (state) => (deviceID: number, sppindex: number, sppsubindex: number) => {
        const alarms = state.alarms;
        const issues = state.issues;
        const issuesByVariable = {
          alarms: [] as DXAlarm[],
          issues: [] as DXIssue[],
        };
        if (alarms && alarms.length > 0) {
          const result = alarms.filter(
            (alarm) =>
              alarm.alarming > 0 &&
              alarm.deviceid == deviceID &&
              alarm.sppindex == sppindex &&
              alarm.sppsubindex == sppsubindex
          );
          if (result && result.length > 0) {
            issuesByVariable["alarms"] = result;
          }
        }
        if (
          issues &&
          typeof issues[deviceID] !== "undefined" &&
          issues[deviceID].length > 0
        ) {
          const result = issues[deviceID].filter(
            (issue) =>
              issue.index == sppindex &&
              issue.subindex == sppsubindex &&
              issue.tsEnd === 0
          );
          if (result && result.length > 0) {
            issuesByVariable["issues"] = result;
          }
        }
        return issuesByVariable;
      },
    getVariablesByDevice() {
      return (deviceID: number) => {
        if (deviceID) {
          const getDeviceType = this.getDeviceTypeById(deviceID);
          const getVariables = this.getVariablesByType;
          const groups = {} as Record<string, DXVariable[]>;
          const type =
            getDeviceType && typeof getDeviceType.type !== "undefined"
              ? getDeviceType.type
              : "";
          const variables = getVariables[type];
          if (type && variables && variables.length > 0) {
            variables.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            });
            variables.forEach((variable) => {
              if (
                variable.showpreview !== undefined &&
                variable.showpreview > 0
              ) {
                if (typeof groups[variable.group] === "undefined") {
                  groups[variable.group] = [];
                }
                groups[variable.group].push(variable);
              }
            });
          }
          const ordered = Object.keys(groups)
            .sort()
            .reduce((obj, key) => {
              obj[key] = groups[key];
              return obj;
            }, {} as Record<string, DXVariable[]>);
          return ordered;
        }
        return false;
      };
    },
    getSingleVariable(state) {
      return (deviceID: string, sppindex: number, sppsubindex: number) => {
        const device = this.getDeviceTypeById(parseInt(deviceID));
        const variables = state.variables;
        if (
          device &&
          typeof device.id !== "undefined" &&
          typeof device.type !== "undefined" &&
          variables &&
          variables.length > 0
        ) {
          const found = variables.find(
            (variable) =>
              variable.deviceType == device.type &&
              variable.index == sppindex &&
              variable.subindex == sppsubindex
          );
          return found;
        }
        return;
      };
    },
    getDevices: (state): DXDevice[] => {
      return state.devices ?? [];
    },
    getDevicesGroupByTags() {
      const devicesByTags = new Map<string, DXDevice[]>(); // key "" is for devices without tags
      this.getDevices.forEach((device) => {
        if (device.tags.length == 0) {
          devicesByTags.set("", [...(devicesByTags.get("") ?? []), device]);
        } else {
          device.tags.forEach((t) =>
            devicesByTags.set(t, [...(devicesByTags.get(t) ?? []), device])
          );
        }
      });
      return [...devicesByTags.entries()].sort((a, b) =>
        a[0].localeCompare(b[0])
      );
    },
    getCurrentValues:
      (state) => (device: string, sppindex: number, sppsubindex: number) => {
        let result: DXCurrentValue[] = [];
        if (
          state.values &&
          parseInt(device) in state.values &&
          Array.isArray(state.values[parseInt(device)])
        ) {
          const matchingValues = state.values[parseInt(device)].filter(
            (e) => e.index === sppindex && e.subindex === sppsubindex
          );
          result.push(...matchingValues);
        }
        if (result.length > 1) {
          result = [result[0]];
        }
        return result.length == 1 ? result[0] : [];
      },
    getCurrentGraph:
      (state) => (deviceID: number, sppindex: number, sppsubindex: number) => {
        const propertyString = `${deviceID}___${sppindex}___${sppsubindex}`;
        if (state.graphs && state.graphs[propertyString])
          return state.graphs[propertyString];
        return false;
      },
    getVariablesByDeviceIDWithMain() {
      return (deviceID: number) => {
        const variables = this.getVariablesByDevice(deviceID);
        if (variables === false) return {};
        const byCategories = {} as Record<string, DXVariable[]>;
        Object.values(variables).forEach((outer) => {
          outer.forEach((v) => {
            let varCategory = v.group;
            if (v.mainrank > 0) {
              varCategory = "main";
            }
            if (!(varCategory in byCategories)) {
              byCategories[varCategory] = [];
            }
            byCategories[varCategory].push(v);
          });
        });
        return byCategories;
      };
    },
    getAlarmsByDeviceID() {
      return (deviceID: number) => {
        const alarms = this.alarms;
        return alarms.filter((a) => a.deviceid == deviceID);
      };
    },
  },
  actions: {
    setDeviceInstances(deviceInstance: DXDeviceHandler) {
      if (this.deviceInstances[deviceInstance.id]) {
        return;
      } else {
        this.deviceInstances[deviceInstance.id] = deviceInstance;
      }
    },
    setIssues(data: DXIssue[]) {
      if (Array.isArray(data) && data.length > 0) {
        const items: Record<number, DXIssue[]> = {};
        data.forEach((issue) => {
          if (!items[issue.device]) {
            items[issue.device] = [];
          }

          items[issue.device].push(issue);
        });
        this.issues = items;
        return items;
      }
    },
    setNewVirtualVariableGroups(id: string, virtualVariables: DXVariable[]) {
      if (this.deviceInstances[id]) {
        this.deviceInstances[id].variableGroups["default"] = virtualVariables;
      }
    },
    setNewVirtualVariables(id: string, virtualVariables: DXVariable[]) {
      if (this.deviceInstances[id]) {
        this.deviceInstances[id].variables = virtualVariables;
        this.setNewVirtualVariableGroups(id, virtualVariables);
      }
    },
    /**
     * Change the tag name for all devices.
     * @param oldName
     * @param newName
     */
    setTagName(oldName: string, newName: string) {
      this.devices.forEach((i) => {
        const idx = i.tags.indexOf(oldName);
        if (idx > -1) {
          i.tags[idx] = newName;
        }
      });
    },
  },
});

export function alarmToOverview(alarm: DXAlarm): DXAlarmOverview {
  return {
    type: "alarm",
    name: alarm.alarmname,
    device: alarm.deviceid,
    sppindex: alarm.sppindex,
    sppsubindex: alarm.sppsubindex,
    tsStart: Date.parse(alarm.alarmingSince),
    tsEnd: 0,
    condition: alarm.condition,
    variable: alarm.variable,
  };
}

export function issueToOverview(issue: DXIssue): DXIssueOverview {
  return {
    type: issue.type,
    name: issue.message,
    device: issue.device,
    sppindex: issue.index,
    sppsubindex: issue.subindex,
    tsStart: issue.tsStart * 1000,
    tsEnd: 0,
    amount: issue?.amount > 0 ? issue.amount : 0,
  };
}

export type DXIssueOverview = {
  type: string;
  name: string;
  device: number;
  sppindex: number;
  sppsubindex: number;
  tsStart: number;
  tsEnd: number;
  amount: number;
};

export type DXAlarmOverview = {
  type: "alarm";
  name: string;
  device: number;
  sppindex: number;
  sppsubindex: number;
  tsStart: number;
  tsEnd: number;
  condition: string;
  variable: string;
};

export type DevicesStoreType = Omit<
  ReturnType<typeof useDeviceStore>,
  keyof ReturnType<typeof defineStore>
>;
