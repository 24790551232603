import { asyncCall } from "./call";
import {
  IMReportRequest,
  InventoryResponse,
  NewInventorySite,
  NewInventoryTank,
  ServerResponse,
  TankAppImage,
} from "./types";

export class InventoryManagementConnector {
  private sessionID: string;
  private urlPrefix: string;

  constructor(urlPrefix: string, sessionID: string) {
    this.urlPrefix = urlPrefix;
    this.sessionID = sessionID;
  }
  /**
   * @target "/api/add-chemical"
   * @param options
   * @returns
   */
  async addChemical(options: { name: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-chemical",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/edit-chemical"
   * @param options
   * @returns
   */
  async editChemical(options: { id: number; name: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/edit-chemical",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/add-process-tank"
   * @param options
   * @returns
   */
  async addProcessTank(options: {
    name: string;
    tank_id: number;
    site_id: number;
    chem_id: number;
    level_device_id: number;
    lat: string;
    lng: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-process-tank",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/add-site"
   * @param options
   * @returns
   */
  async addSite(options: {
    name: string;
    lat: string;
    lng: string;
    address: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-site",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/add-tank-type"
   * @param options
   * @returns
   */
  async addTankType(options: NewInventoryTank) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-tank-type",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/add-warehouse-tank"
   * @param options
   * @returns
   */
  async addWarehouseTank(options: { site_id: number; chem_id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/add-warehouse-tank",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/del-chemical"
   * @param options
   * @returns
   */
  async delChemical(options: { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/del-chemical",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/del-process-tank"
   * @param options
   * @returns
   */
  async delProcessTank(options: { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/del-process-tank",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/del-site"
   * @param options
   * @returns
   */
  async delSite(options: { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/del-site",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/del-tank-type"
   * @param options
   * @returns
   */
  async delTankType(options: { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/del-tank-type",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/del-warehouse-tank"
   * @param options
   * @returns
   */
  async delWarehouseTank(options: { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/del-warehouse-tank",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/edit-site"
   * @param options
   * @returns
   */
  async editSite(options: NewInventorySite & { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/edit-site",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/edit-process-tank"
   * @param options
   * @returns
   */
  async editProcessTank(options: {
    id: number;
    name: string;
    tank_id: number;
    site_id: number;
    chem_id: number;
    level_device_id: number;
    lat: string;
    lng: string;
  }) {
    const result: ServerResponse | number = await asyncCall(
      this.urlPrefix + "/api/edit-process-tank",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/edit-warehouse-tank"
   * @param options
   * @returns
   */
  async editWarehouseTank(options: {
    id: number;
    site_id: number;
    chem_id: number;
  }) {
    const result: ServerResponse | number = await asyncCall(
      this.urlPrefix + "/api/edit-warehouse-tank",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/edit-tank-type"
   * @param options
   * @returns
   */
  async editTankType(options: NewInventoryTank & { id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/edit-tank-type",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-inventory"
   * @returns
   */
  async getInventory() {
    const result: InventoryResponse | ServerResponse = await asyncCall(
      this.urlPrefix + "/api/get-inventory",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-tank-image"
   * @returns
   */
  async getTankImage() {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/get-tank-image",
      {
        session: this.sessionID,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-tank-app-images"
   * @param options
   * @returns
   */
  async getTankAppImages(options: { id: number }) {
    const result: ServerResponse | TankAppImage[] = await asyncCall(
      this.urlPrefix + "/api/get-tank-app-images",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/request-inventory-management-report"
   * @param options
   * @returns
   */
  async requestInventoryManagementReport(options: IMReportRequest) {
    const result: ServerResponse | string = await asyncCall(
      this.urlPrefix + "/api/request-inventory-management-report",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-report-status"
   * @param options
   */
  async requestInventoryManagementReportStatus(options: { task_id: string }) {
    const result: ServerResponse | string = await asyncCall(
      this.urlPrefix + "/api/get-report-status",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-tank-image"
   * @param options
   * @returns
   */
  async setTankImage(options: { tank: number; image: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-tank-image",
      {
        session: this.sessionID,
        tank: options.tank,
      },
      options.image
    );
    return result;
  }

  /**
   * @target "/api/set-warehouse-amount"
   * @param options
   * @returns
   */
  async setWarehouseAmount(options: {
    tank_id: number;
    size: number;
    in_store: number;
    in_delivery: number;
    ordered: number;
    change_id: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-warehouse-amount",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/undo-warehouse-change"
   * @param options
   * @returns
   */
  async undoWarehouseItemChange(options: {
    tank_id: number;
    size: number;
    change_id: string;
  }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/undo-warehouse-change",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-warehouse-changes"
   * @param options
   * @returns
   */
  async getWarehouseItemsChanges(options: { tank_id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/get-warehouse-changes",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/get-tank-sizes"
   * @param options
   * @returns
   */
  async getTankSizes(options: { tank_id: number }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/get-tank-sizes",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }

  /**
   * @target "/api/set-tank-size"
   * @param options
   * @returns
   */
  async setTankSize(options: { tank_id: number; tank_sizes: string }) {
    const result: ServerResponse = await asyncCall(
      this.urlPrefix + "/api/set-tank-size",
      {
        session: this.sessionID,
        ...options,
      }
    );
    return result;
  }
}
