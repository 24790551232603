// Routing
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalizedGeneric,
} from "vue-router";
import { isUserAuthenticated } from "./Auth";
import {
  useGlobalConfigsStore,
  useUISettingsStore,
  useUserConfigStore,
} from "./storeModules";
import { getBrandInfo } from "./main";
import { useIsMobile } from "./utils/useIsMobile";
import { DXUserRights } from "./types";

const routes = [
  {
    path: "/",
    redirect: {
      name: "Dashboard",
    },
  },
  {
    path: "/unauthorized",
    component: () => import("./components/views/Unauthorized/Unauthorized.vue"),
    name: "Unauthorized",
  },
  {
    path: "/desktop-only",
    component: () =>
      import(
        "./components/views/DesktopOnlyIndicator/DesktopOnlyIndicator.vue"
      ),
    name: "Not Allowed On Mobile",
  },
  {
    path: "/platform/:path(.*)",
    redirect: "/",
  },
  {
    path: "/manage/wl-preview",
    name: "WL Preview",
    component: () => import("./components/views/WLPOC/WLForm.vue"),
    meta: {
      isWLTools: true,
    },
    before: () => {
      return useUserConfigStore().hasWLTools;
    },
  },
  {
    path: "/grant-access",
    name: "Grant Access",
    component: () => import("./components/views/GrantAccessView.vue"),
    meta: {
      isPublic: true,
      isWLFriendly: true,
    },
  },
  {
    path: "/signupauth",
    name: "SignupAuth",
    component: () => import("./components/views/SignupAuthView.vue"),
    meta: {
      isPublic: true,
      isWLFriendly: true,
    },
  },

  {
    path: "/value-history",
    name: "Value History",
    component: () =>
      import("./components/views/Devices/ValueHistory/ValueHistoryView.vue"),
    meta: {
      isDesktopOnly: true,
    },
  },
  {
    path: "/device-list/:tag?",
    component: () =>
      import("./components/views/Devices/List/DeviceListView.vue"),
    name: "Device List",
  },
  {
    path: "/device-management",
    component: () => import("./components/views/Devices/Device.vue"),
    name: "DeviceManagement",
    children: [
      {
        path: "overview",
        component: () =>
          import("./components/views/Devices/Overview/DeviceOverview.vue"),
        name: "Device Overview",
      },
      {
        path: "values",
        component: () =>
          import("./components/views/Devices/Values/DeviceValuesView.vue"),
        name: "Device Values",
      },
      {
        path: "alarms",
        component: () =>
          import("./components/views/Devices/Alarms/DeviceAlarmView.vue"),
        name: "Device Alarms",
      },
      {
        path: "log",
        component: () =>
          import("./components/views/Devices/Log/DeviceLogView.vue"),
        name: "Device Log",
      },
      {
        path: "value-graph",
        name: "Value Graph",
        component: () =>
          import(
            "./components/views/Devices/Graphs/DeviceVariableGraphView.vue"
          ),
      },
      {
        path: "virtual-variables",
        name: "Virtual Variables",
        component: () =>
          import("./components/views/VirtualDevices/VirtualDevicesView.vue"),
        meta: { mathBeta: true },
      },
    ],
  },

  {
    path: "/welcome",
    component: () => import("./components/views/WelcomePage/WelcomePage.vue"),
    name: "Welcome",
    meta: { isPublic: true, isWLFriendly: false },
  },
  {
    path: "/contact",
    component: () => import("./components/views/WelcomePage/ContactForm.vue"),
    name: "Welcome/Contact",
    meta: { isPublic: true, isWLFriendly: false },
  },
  {
    path: "/faq",
    component: () => import("./components/views/WelcomePage/FAQ.vue"),
    name: "Welcome/FAQ",
    meta: { isPublic: true, isWLFriendly: false },
  },
  {
    path: "/documents",
    component: () => import("./components/views/WelcomePage/DocumentsPage.vue"),
    name: "Documents",
    meta: { isPublic: true, isWLFriendly: false },
  },
  {
    path: "/signup",
    component: () => import("./components/views/SignUp/SignUp.vue"),
    name: "Signup",
    meta: { isPublic: true, canUserDisplay: false },
  },

  {
    path: "/login",
    component: () => import("./components/views/Login/LoginScreen.vue"),
    name: "Login",
    meta: {
      isPublic: true,
      canUserDisplay: false,
    },
  },
  {
    path: "/reset-password",
    component: () =>
      import("./components/views/ResetPassword/ResetPassword.vue"),
    name: "Reset",
    meta: {
      isPublic: true,
      canUserDisplay: false,
    },
  },
  {
    path: "/new-password",
    component: () => import("./components/views/NewPassword/NewPassword.vue"),
    name: "New Password",
    meta: {
      isPublic: true,
      canUserDisplay: false,
    },
  },
  {
    path: "/dashboard/:dashboardID?",
    component: () => import("./components/library/Dashboard/Dashboard.vue"),
    name: "Dashboard",
    props: {
      isIM: false,
    },
  },
  {
    path: "/whats-new",
    component: () => import("./components/views/WhatsNew.vue"),
    name: "whatsNew",
  },
  {
    path: "/profile",
    component: () => import("./components/views/ProfilePage/Profile.vue"),
    name: "Profile",
    meta: {
      isPublic: false,
      isWLFriendly: true,
      // requiredPermission: "manage_profile",
    },
  },
  {
    path: "/logout",
    component: () => import("./components/views/Logout.vue"),
    name: "Logout",
  },
  {
    path: "/inventory-management",
    component: () => import("./components/views/IM/IM.vue"),
    name: "IM",
    meta: {
      isDesktopOnly: true,
    },
    children: [
      {
        path: "dashboard/:dashboardID?",
        component: () => import("./components/library/Dashboard/Dashboard.vue"),
        name: "IMDashboard",
        props: {
          isIM: true,
        },
        before: () => {
          useUISettingsStore().activeDashboardID = "";
        },
      },

      {
        path: "/reports",
        component: () =>
          import("./components/views/IM/Report/IMReportDashboard.vue"),
        name: "Reports",
      },
      {
        path: "/report/generate/:title",
        component: () =>
          import("./components/views/IM/Report/IMAccordianReport.vue"),
        name: "GenerateIMReports",
      },
      {
        path: "list/:sortby?/:orderby?",
        component: () => import("./components/views/IM/Inventory/IMList.vue"),
        name: "IMList",
      },
      {
        path: "site/view/:sortby?/:orderby?",
        component: () =>
          import("./components/views/IM/Inventory/IMSiteView.vue"),
        name: "IMSiteView",
      },
      {
        path: "tankapp/:id",
        component: () =>
          import("./components/views/IM/TankLevelApp/IMTankApp.vue"),
        name: "IMTankApp",
      },
      {
        path: "tankapp/:id/edit",
        component: () =>
          import("./components/views/IM/TankLevelApp/IMTankAppEdit.vue"),
        name: "IMTankAppEdit",
      },
      {
        path: "tankapp/:id/remove",
        component: () =>
          import("./components/views/IM/TankLevelApp/IMTankAppRemove.vue"),
        name: "IMTankAppRemove",
      },
      {
        path: "tankapp/new",
        component: () =>
          import("./components/views/IM/TankLevelApp/IMTankAppNew.vue"),
        name: "IMTankAppNew",
      },
      {
        path: "tankapp/:id/warehouse",
        component: () =>
          import("./components/views/IM/TankLevelApp/IMWarehouseEdit.vue"),
        name: "IMWarehouseEdit",
      },
      {
        path: "sites/:sortby?/:orderby?",
        component: () => import("./components/views/IM/Site/IMSites.vue"),
        name: "IMSites",
      },
      {
        path: "site/:id/edit",
        component: () => import("./components/views/IM/Site/IMSiteEdit.vue"),
        name: "IMSiteEdit",
      },
      {
        path: "site/:id/remove",
        component: () => import("./components/views/IM/Site/IMSiteRemove.vue"),
        name: "IMSiteRemove",
      },
      {
        path: "site/new",
        component: () => import("./components/views/IM/Site/IMSiteNew.vue"),
        name: "IMSiteNew",
      },
      {
        path: "tanks",
        component: () => import("./components/views/IM/Tank/IMTanks.vue"),
        name: "IMTanks",
      },
      {
        path: "tank/new",
        component: () => import("./components/views/IM/Tank/IMTankNew.vue"),
        name: "IMTankNew",
      },
      {
        path: "tank/:id/edit",
        component: () => import("./components/views/IM/Tank/IMTankEdit.vue"),
        name: "IMTankEdit",
      },
      {
        path: "tank/:id/remove",
        component: () => import("./components/views/IM/Tank/IMTankRemove.vue"),
        name: "IMTankRemove",
      },
      {
        path: "chemicals",
        component: () =>
          import("./components/views/IM/Chemical/IMChemicals.vue"),
        name: "IMChemicals",
      },
      {
        path: "chemical/new",
        component: () =>
          import("./components/views/IM/Chemical/IMChemicalNew.vue"),
        name: "IMChemicalNew",
      },
      {
        path: "chemical/:id/edit",
        component: () =>
          import("./components/views/IM/Chemical/IMChemicalEdit.vue"),
        name: "IMChemicalEdit",
      },
      {
        path: "chemical/:id/remove",
        component: () =>
          import("./components/views/IM/Chemical/IMChemicalRemove.vue"),
        name: "IMChemicalRemove",
      },
      {
        path: "map",
        component: () => import("./components/views/IM/Map/IMmap.vue"),
        name: "IMMap",
      },
    ],
  },
  {
    path: "/advanced-reporting",
    component: () =>
      import("./components/views/AdvancedReporting/ReportsOnboarding.vue"),
    name: "ReportsOnboarding",
  },
  {
    path: "/report-management",
    component: () => import("./components/views/AdvancedReporting/Reports.vue"),
    name: "ReportsLayout",
    children: [
      {
        path: "report-requests",
        component: () =>
          import(
            "./components/views/AdvancedReporting/ManageReports/ManageReportRequests.vue"
          ),
        name: "ManageDeviceReport",
      },
      {
        path: "values-template",
        component: () =>
          import(
            "./components/views/AdvancedReporting/ManageReports/ManageValuesTemplate.vue"
          ),
        name: "ManageValuesTemplate",
      },
    ],
  },
  {
    path: "/create-report-request/:reportRequestId?/:type?",
    component: () =>
      import(
        "./components/views/AdvancedReporting/ManageReports/GenerateReport.vue"
      ),
    name: "CreateReportRequest",
    props: true,
  },
  {
    path: "/report-documents",
    component: () =>
      import(
        "./components/views/AdvancedReporting/DownloadReports/DownloadReports.vue"
      ),
    name: "DownloadReports",
  },
  {
    path: "/maths-editor",
    component: () => import("./components/views/MATHS/Maths3Editor.vue"),
    name: "Maths Editor",
    meta: { mathBeta: true },
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("./components/layout/404.vue"),
    name: "404",
  },
];

export const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth", top: 64 };
    } else {
      return { top: 0 };
    }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes, // short for `routes: routes`
});

router.beforeEach(async (to) => {
  // these need to be declared here otherwise they will access a non-existing pinia instance
  const globalConfigStore = useGlobalConfigsStore();
  const { checkPermission } = useUserConfigStore();
  // Get the brand info first if it hasn't been set yet
  if (globalConfigStore.brand === "") {
    await getBrandInfo();
  }

  const { isMobile, isReactNative } = useIsMobile();
  if ((isMobile.value || isReactNative.value) && to.meta.isDesktopOnly) {
    return "/desktop-only";
  }

  // Make sure the authentication status is up to date
  if (globalConfigStore.isAuth === undefined) {
    try {
      globalConfigStore.$patch({ isAuth: await isUserAuthenticated() });
    } catch (error) {
      console.error(error);
    }
  }

  if (!checkPermission(to.meta?.requiredPermission as keyof DXUserRights)) {
    return "/unauthorized";
  }

  // Actual routing logic
  // First check: do not show the page if the page is marked as not WL friendly and the brand is not dulconnex
  if (
    to.meta.isWLFriendly === false &&
    globalConfigStore.brand !== "dulconnex"
  ) {
    return globalConfigStore.brand !== "dulconnex" ? "/login" : "/welcome";
  }

  // Check if user is authenticated
  if (globalConfigStore.isAuth === false) {
    // If user is not authenticated and the page is marked as public, page can be seen
    if (to.meta.isPublic === true) {
      return true;
      // If the user is not authenticated and wants to see a private page other than logout
      // Save it to the backlink.
      // Logout is an exceptional page as it can create an infinite logout cycle with backlink
      // Also, we want it to be accessible even if there is a problem with the authentication
    } else {
      if (to.name !== "Logout" && findIfRouteExists(to)) {
        // save target for backlink
        // backlinking to logout can create an infinite logout cycle
        globalConfigStore.backlink = to;
      }
      // DX users should be redirected to the /welcome page instead of /login page.
      if (globalConfigStore.brand === "dulconnex") {
        if (isReactNative?.value) {
          return "/login";
        } else {
          return "/welcome";
        }
      } else {
        return "/login";
      }
    }
  } else {
    // User is authenticated
    // But they may still try to reach a page that we do not have anymore such as '/signup.html'
    if (!findIfRouteExists(to)) {
      // If the route does not exist, we redirect to the dashboard
      return "/dashboard";
    }
    // Also there are some public pages we don't want authenticated users to see.
    // Check if the page can be seen by user.
    if (to.meta.canUserDisplay === false) {
      if (isReactNative?.value) {
        return "/dashboard";
      } else {
        return "/profile";
      }
      // Internal pages:
    } else if (to.meta.isWLTools) {
      return useUserConfigStore().hasWLTools || "/dashboard";
    } else {
      // math beta check
      const userStore = useUserConfigStore();
      if (to.meta.mathBeta && !userStore.hasMathFunctionality) {
        return "/dashboard";
      }
      // If no exception has been thrown, the page can be seen
      return true;
    }
  }
});

localStorage.setItem("displayFooter", import.meta.env.VITE_SHOW_FOOTER);

// dictionary for route names => Human readable page titles
const nameToTitleDic: Map<string, string> = new Map();
nameToTitleDic.set("Dashboard", "Dashboard");
nameToTitleDic.set("Reports", "IM - Reports");
nameToTitleDic.set("IM", "Inventory Management");
nameToTitleDic.set("IMDashboard", "IM - Dashboard");
nameToTitleDic.set("IMDashboardSort", "IM - Dashboard");
nameToTitleDic.set("IMList", "IM - List");
nameToTitleDic.set("IMSites", "IM - Sites");
nameToTitleDic.set("IMTankApp", "IM - TankLevelApp");
nameToTitleDic.set("IMTankAppEdit", "IM - TankLevelApp");
nameToTitleDic.set("IMTankAppRemove", "IM - TankLevelApp");
nameToTitleDic.set("IMTankAppNew", "IM - TankLevelApp");
nameToTitleDic.set("IMWarehouseEdit", "IM - Warehouse");
nameToTitleDic.set("IMSite", "IM - Site");
nameToTitleDic.set("IMSiteEdit", "IM - Site");
nameToTitleDic.set("IMSiteRemove", "IM - Site");
nameToTitleDic.set("IMSiteNew", "IM - Site");
nameToTitleDic.set("IMTanks", "IM - Tanks");
nameToTitleDic.set("IMTankNew", "IM - Tank");
nameToTitleDic.set("IMTankEdit", "IM - Tank");
nameToTitleDic.set("IMTankRemove", "IM - Tank");
nameToTitleDic.set("IMChemicals", "IM - Chemicals");
nameToTitleDic.set("IMChemicalNew", "IM - Chemical");
nameToTitleDic.set("IMChemicalRemove", "IM - Chemical");
nameToTitleDic.set("IMMap", "IM - Map");
nameToTitleDic.set("IMCardAdd", "IM - Card");
nameToTitleDic.set("IMCardEdit", "IM - Card");
nameToTitleDic.set("IMCardRemove", "IM - Card");
nameToTitleDic.set("IMWidgetAdd", "IM - Widget");
nameToTitleDic.set("IMWidgetEdit", "IM - Widget");
nameToTitleDic.set("IMWidgetRemove", "IM - Widget");
nameToTitleDic.set("404", "404");

router.afterEach((to) => {
  const titleBase = useGlobalConfigsStore().brand.toUpperCase() + " | ";
  const toTitle = to.name?.toString() ?? "";
  const specificTitle = nameToTitleDic.get(toTitle) ?? toTitle;
  document.title = titleBase + specificTitle;
});

export const findIfRouteExists = (to: RouteLocationNormalizedGeneric) => {
  const routeMatch = to.matched;

  // If the route exists and it's not '404' allows backlinking. Otherwise return false
  const result =
    routeMatch && routeMatch.length > 0 && routeMatch[0].name !== "404";
  return result;
};
