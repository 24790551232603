import type { DXUserRights } from "@/types";

export const clearLocalStorage = () => {
  localStorage.removeItem("prominent.session");
  localStorage.removeItem("prominent.user");
  localStorage.removeItem("prominent.user.email");
  localStorage.removeItem("prominent.user.level");
  localStorage.removeItem("prominent.access.platform");
  localStorage.removeItem("prominent.access.reports");
  localStorage.removeItem("prominent.access.im");
  localStorage.removeItem("prominent.tmp");
  localStorage.removeItem("prominent.backlink");
  localStorage.removeItem("prominent.userData");
  localStorage.removeItem("prominent.userRights");
};

export const parseUserRights: () => DXUserRights = () => {
  const userRightsEncoded = localStorage.getItem("prominent.userRights");
  if (userRightsEncoded === null) {
    return {};
  }

  const userRightsDecoded = atob(userRightsEncoded);
  const userRightsObject = JSON.parse(userRightsDecoded);
  return userRightsObject;
};
