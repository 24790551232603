import { CurrentValue, Session, Settings } from "@/api/types";
import { DXNews, DXUserRights } from "@/types";
import { defineStore } from "pinia";

export const useUserConfigStore = defineStore("userConfig", {
  state: () => ({
    maxDashboards: 99,
    maxCards: 99,
    maxWidgets: 99,
    featureLimit: false,
    userName: false, //! not present in store anymore
    userContract: false, //! not present in store anymore
    newsList: [] as DXNews[],
    userData: {} as Partial<Session>,
    current: [] as CurrentValue[],
    completedTours: [] as string[],
    userSettings: {} as Settings,
    userRights: {} as DXUserRights,
    // TODO: REMOVE THIS WHEN THE BEIS READY
    BYPASS_USER_RIGHTS: true,
  }),
  getters: {
    hasMathFunctionality(state) {
      return (
        state.userData?.accessRights?.["access-math-functionality"] === true
      );
    },
    hasAdvancedReporting(state) {
      return (
        state.userData?.accessRights?.["access-advanced-reporting"] === true
      );
    },
    hasWLTools(state) {
      return (
        // TODO: Clean it after the BE implementation.
        state.userData?.accessRights?.["access-wl-tools"] === true ||
        state.userData.contract === "foofoo42"
      );
    },
    checkPermission:
      (state) => (permissionKey: keyof DXUserRights | undefined) => {
        // TODO: REMOVE THIS WHEN THE BEIS READY
        if (state.BYPASS_USER_RIGHTS) {
          return true;
        }

        // In case the required permission key is not found, which means the page does not require permission.
        if (!permissionKey) {
          return true;
        }
        try {
          if (state.userRights[permissionKey] === true) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          // In case of error, do not give permission.
          return false;
        }
      },
    canAddDevice(state) {
      // TODO: REMOVE THIS WHEN THE BEIS READY
      if (state.BYPASS_USER_RIGHTS) {
        return true;
      }
      if (!state.userRights.login_platform) {
        return false;
      }
      return (
        state.userRights.add_device ||
        state.userRights.add_new_device ||
        state.userRights.add_any_device_directly
      );
    },
    canManageProfile(state) {
      // TODO: REMOVE THIS WHEN THE BEIS READY
      if (state.BYPASS_USER_RIGHTS) {
        return true;
      }
      if (!state.userRights.login_platform) {
        return false;
      }
      return state.userRights.manage_profile;
    },
    canManageAlarms(state) {
      // TODO: REMOVE THIS WHEN THE BEIS READY
      if (state.BYPASS_USER_RIGHTS) {
        return true;
      }
      if (!state.userRights.login_platform) {
        return false;
      }
      return state.userRights.manage_alarms;
    },
  },
});
