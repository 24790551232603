<template>
  <div class="tw-flex tw-items-center tw-gap-1">
    <span v-if="!labelRightToToggle" class="tw-ml-2 tw-mr-2">
      <div v-html="$t(label)" />
    </span>
    <label class="tw-flex tw-items-center tw-cursor-pointer">
      <input
        :disabled="disabled"
        type="checkbox"
        class="tw-hidden"
        v-model="writableModelValue"
      />
      <div
        class="tw-relative tw-w-12 tw-h-6 tw-bg-gray-200 tw-rounded-full tw-transition-colors tw-duration-200 tw-ease-in-out"
      >
        <div
          class="tw-absolute tw-inset-0 tw-w-6 tw-h-6 tw-rounded-full tw-shadow-md tw-transition-transform tw-duration-200 tw-ease-in-out"
          :class="{
            'tw-translate-x-7': writableModelValue,
            'tw-bg-primary-500': writableModelValue && !disabled,
            'tw-bg-gray-400': !writableModelValue || disabled,
            'tw-border-solid tw-border-red-500': hasValidationError,
          }"
        ></div>
      </div>
    </label>
    <span v-if="labelRightToToggle" class="tw-ml-2 tw-mr-2">
      <div v-html="$t(label)" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  modelValue: { type: Boolean, required: true },
  labelRightToToggle: { type: Boolean },
  label: { type: String, default: "" },
  disabled: {
    type: Boolean,
    default: false,
  },
  hasValidationError: { type: Boolean, default: false }
});

const writableModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(v: boolean) {
    emit("update:modelValue", v);
  },
});

const emit = defineEmits<{ (e: "update:modelValue", v: boolean): void }>();
</script>
