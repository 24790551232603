import { DXTour } from "@/components/library/GuidedTour/GuidedTourUtils";
import { createAddVirtualDevicesTour, createAddVirtualVariableTour, createMathEditorTour, createReportGeneratorTour } from "@/utils/guidedTours/tourFactory";
import { defineStore } from "pinia";
import { nextTick } from "vue";
import { useGlobalConfigsStore, useUISettingsStore } from "..";
import { DXCompletedTourInfo } from "@/types";
import { TOUR_CATEGORIES } from "@/constants/tour"

export const useGuidedTourStore = defineStore("guidedTour", {
  state: () => ({
    completedTours: [] as DXCompletedTourInfo[] | undefined,
    completedOrSavedToursByCategory: {} as Record<string, DXCompletedTourInfo[]>,
    allToursByCategory: {} as Record<string, DXTour[]>,
    tourStarted: false,
    activeTour: null as null | DXTour,
    currentStep: 0,
  }),
  getters: {
    getCurrentStep(state) {
      const step = state.activeTour?.steps?.[state.currentStep];
      return step ?? null;
    },
    getActiveElementID(state) {
      return (
        state.activeTour?.steps?.[state.currentStep]?.options?.elementTourID ??
        ""
      );
    },
    isLastStep(state) {
      return state.currentStep === (state.activeTour?.steps.length ?? 0) - 1;
    },
  },
  actions: {
    async initializeTours() {
      const addVirtualDevicesTour = createAddVirtualDevicesTour();
      const addVirtualVariableTour = createAddVirtualVariableTour();
      const mathEditorTour = createMathEditorTour();
      const reportGeneratorTour = createReportGeneratorTour();
      this.allToursByCategory[TOUR_CATEGORIES.MATH_FUNCTIONALITY] = [addVirtualDevicesTour, addVirtualVariableTour, mathEditorTour];
      this.allToursByCategory[TOUR_CATEGORIES.REPORT_GENERATOR] = [reportGeneratorTour];
      await this.getCompletedOrSavedTours();
    },
    async getCompletedOrSavedTours() {
      try {
        this.completedOrSavedToursByCategory = {};
        const completedTours = await useGlobalConfigsStore().connector?.general.getSavedOrCompletedTour() ?? [];
        completedTours.forEach(tourData => {
          if (!this.completedOrSavedToursByCategory[tourData.section]) {
            this.completedOrSavedToursByCategory[tourData.section] = [];
          }
          this.completedOrSavedToursByCategory[tourData.section].push(tourData);
        })
        return this.completedOrSavedToursByCategory;
      } catch (error) {
        console.error(error);
      }
    },
    setSkipped(value: boolean) {
      if(this.activeTour)
      this.activeTour.isSkipped = value;
    },
    async getTourByIdAndCategory(category: string, tourId: string): Promise<DXTour | undefined> {
        const toursInCategory = this.allToursByCategory[category];
        if (!toursInCategory) {
          return undefined;
        }
        return toursInCategory.find(tour => tour.id === tourId);
    },
    async incrementStep() {
      if (
        this.activeTour &&
        this.currentStep + 1 < this.activeTour?.steps.length
      ) {
        const nextStepOptions = this.activeTour?.steps[this.currentStep + 1]?.options;
        if (nextStepOptions?.beforeStep) {
          nextStepOptions.beforeStep();
        }
        await nextTick();
        this.currentStep++;
      } else {
        this.closeModal()
      }
    },
    decrementStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        this.getCurrentStep?.options?.beforeStep?.();
      }
    },
    async loadTour(tour: DXTour, currentStep: number) {
      const uiStore = useUISettingsStore();
      this.activeTour = null;
      this.activeTour = tour;
      this.currentStep = currentStep;
      this.tourStarted = true;
      this.setSkipped(false);
      uiStore.setGuidedTour(true);
    },
    closeModal() {
      if(this.activeTour)
      this.tourStarted = false;
      this.currentStep = 0;
      this.activeTour = null;      
    }
  },
});
