import { defineStore } from 'pinia';
import { ReportRequest, DXValuesTemplate, GenerateReportData, DeviceReport } from '@/types';
import { useGlobalConfigsStore } from '@/storeModules';

export const useReportStore = defineStore('reporting', {
  state: () => ({
    reportRequests: [] as ReportRequest[],
    valuesTemplates: [] as DXValuesTemplate[],
    deviceReports: [] as DeviceReport[],
  }),
  getters: {

    getReportRequestById: (state) => (id: number): ReportRequest | undefined => {
      return state.reportRequests.find(request => request.requestId === id);
    },

    dropdownOptions: (state) => (selectedDeviceType: string) => {
      return state.valuesTemplates
        .filter(template => template.deviceType === selectedDeviceType)
        .map(template => ({
          value: template.id,
          text: template.name,
        }));
    },
  },
  actions: {
    async getReportRequests() {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        this.reportRequests = await globalStore.connector.devices.getReportRequests() || [];
      }
    },
    async deleteReportRequest(id: number) {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        await globalStore.connector.devices.deleteReportRequest(id);
        await this.getReportRequests();
      }
    },
    async setActiveStatus(id: number, isActive: boolean) {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        await globalStore.connector.devices.activateDeactivateReportRequest(id, isActive);
        await this.getReportRequests();
      }
    },
    async createReportRequest(reportRequestData: GenerateReportData) {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        await globalStore.connector.devices.createReportRequest(reportRequestData);
        await this.getReportRequests();
      }
    },
    async updateReportRequest(reportRequestData: GenerateReportData, requestId: number) {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        await globalStore.connector.devices.updateReportRequest(reportRequestData, requestId);
        await this.getReportRequests();
      }
    },
    async getValuesTemplates() {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        this.valuesTemplates = await globalStore.connector.devices.getValuesTemplates() || [];
      }
    },
    /**
     * Saves a new or updated values template.
     * @param payload - An object containing mappingId, name, deviceType, and values.
     * @param actionType - "new" for creating or "edit" for updating.
     */
    async saveValuesTemplate(
      payload: { mappingId: number; name: string; deviceType: string; values: any; },
      actionType: "new" | "edit"
    ) {
      const globalStore = useGlobalConfigsStore();
      if (!globalStore.connector) return;
      try {
        if (actionType === "edit") {
          await globalStore.connector.devices.updateValuesTemplates(payload);
        } else {
          await globalStore.connector.devices.setValuesTemplates(payload);
        }
        await this.getValuesTemplates();
      } catch (error) {
        console.error("Error saving values template:", error);
        throw error;
      }
    },
    async deleteValuesTemplate(id: number) {
        const globalStore = useGlobalConfigsStore();
        if (globalStore.connector) {
          await globalStore.connector.devices.deleteValuesTemplate(id);
          await this.getValuesTemplates();
        }
      },
    async getDeviceReports() {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        this.deviceReports = await globalStore.connector.devices.getDeviceReports() || [];
      }
    },  
    async deleteDeviceReport(id: number) {
      const globalStore = useGlobalConfigsStore();
      if (globalStore.connector) {
        await globalStore.connector.devices.deleteDeviceReport(id);
        await this.getDeviceReports();
      }
    },
  },
});

