<template>
  <BaseLabel
    :inputId="inputId!"
    :label="label || ''"
    :labelAlignment="labelAlignment"
    :additionalData="additionalData"
    dense
  >
    <select
      :class="`${
        disabled ? 'tw-opacity-50' : ''
      }  tw-input-base tw-outline-none tw-p-2 ${
        dense ? 'tw-py-3 tw-my-0' : ''
      }`"
      :value="modelValue"
      :id="inputId"
      :disabled="disabled"
      @change="handleChange"
      v-if="type === 'select'"
      :isValid="isValid"
      :data-test="dataTest"
      :required="required"
    >
      <option
        v-if="placeHolderText"
        disabled
        selected
        :value="typeof modelValue === 'number' ? 0 : ''"
      >
        {{ placeHolderText }}
      </option>
      <option v-if="options.length === 0" disabled>
        No options available
      </option>
      <template v-for="option in options">
        <option
          v-if="'value' in option"
          :key="JSON.stringify(option.value)"
          :value="option.value"
          :disabled="option.disabled"
          :selected="option.selected"
        >
          {{ option.text }}
        </option>
        <optgroup :label="option.text" v-else :key="option.text">
          <option
            v-for="child of option.children"
            :key="JSON.stringify(child.value)"
            :value="child.value"
            :disabled="child.disabled"
            :selected="child.selected"
          >
            {{ child.text }}
          </option>
        </optgroup>
      </template>
    </select>
    <div
      v-else
      class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-start tw-space-x-6"
    >
      <template v-for="option in options">
        <div
          v-if="'value' in option"
          class="tw-space-x-2"
          :key="JSON.stringify(option.value)"
        >
          <label
            class="tw-p-2 tw-inline-block"
            :for="JSON.stringify(option.value)"
            >{{ option.text }}</label
          >
          <input
            class="tw-align-text-bottom"
            :key="JSON.stringify(option.value)"
            type="radio"
            :id="JSON.stringify(option.value)"
            :value="option.value"
            :checked="option.selected || radioValue === option.value"
            @input="handleRadioChange(option.value)"
            :disabled="option.disabled"
            :data-test="dataTest + option.value"
          />
        </div>
      </template>
    </div>
  </BaseLabel>
</template>
<script setup lang="ts">
import { PropType, ref } from "vue";
import { BaseLabelProps } from "../BaseLabel/BaseLabelUtils";
import BaseLabel from "../BaseLabel/BaseLabel.vue";
import { SelectOptions, SelectType } from "./BaseSelectUtils";

const props = defineProps({
  placeHolderText: {
    type: String,
  },
  options: {
    type: Array as PropType<SelectOptions[]>,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  modelValue: {
    type: [String, Number, Object, Boolean, Array],
    required: true,
  },
  required: {
    type: Boolean,
    required: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  type: { type: String as PropType<SelectType>, default: "select" },
  isValid: { type: Boolean, required: false, default: undefined },
  dataTest: { type: String, default: "" },
  ...BaseLabelProps,
});

const emit = defineEmits<{
  (e: "update:modelValue", v: string | number | object | boolean): void;
}>();
const radioValue = ref(props.modelValue);

const handleRadioChange = (value: string | number | object | boolean) => {
  radioValue.value = value;
  emit("update:modelValue", value);
};

const handleChange = (event: Event) => {
  const target = event.target;
  if (target instanceof HTMLSelectElement) {
    const value = target.value;
    emit("update:modelValue", value);
  }
};
</script>
