<template>
  <div class="tw-h-screen tw-w-screen tw-flex tw-flex-col tw-overflow-hidden">
    <DXHeader />
    <div class="tw-h-16 tw-flex-auto tw-flex tw-relative">
      <!-- Nav -->
      <ShrinksWithNavBar>
        <Navigation v-if="globalStore.isAuth" :items="navBarItems"></Navigation>
      </ShrinksWithNavBar>
      <!-- Page Content -->

      <div
        class="tw-bg-[#f3f4f6] tw-w-full tw-overflow-y-auto tw-flex tw-overflow-x-hidden tw-flex-col"
        @touchstart="(e) => handleTouch(e, 'start')"
        @touchmove="(e) => handleTouch(e, 'move')"
        @touchend="(e) => handleTouch(e, 'end')"
      >
        <div
          class="tw-fixed tw-top-24 tw-w-full tw-text-center tw-text-4xl tw-text-primary-700"
          v-if="
            (touch.distance > 100 && touch.ptr) || touch.success || touch.error
          "
          :style="{ top: touch.distance }"
        >
          <font-awesome-icon
            v-if="touch.distance > 100 && touch.distance < 200"
            :icon="['fas', 'arrow-down']"
            class="tw-bg-slate-500 tw-rounded-full tw-p-2 tw-w-auto"
          />
          <font-awesome-icon
            v-if="
              touch.distance > 200 &&
              !touch.pending &&
              !touch.success &&
              !touch.error
            "
            :icon="['fas', 'refresh']"
            class="tw-bg-slate-500 tw-rounded-full tw-p-2 tw-w-auto"
          />
          <font-awesome-icon
            v-if="touch.pending"
            :icon="['fas', 'spinner']"
            class="tw-bg-slate-500 tw-rounded-full tw-p-2 tw-w-auto tw-animate-spin"
          />
          <font-awesome-icon
            v-if="touch.success"
            :icon="['fas', 'check']"
            class="tw-bg-status-green-500 tw-rounded-full tw-p-2 tw-w-auto"
          />
          <font-awesome-icon
            v-if="touch.error"
            :icon="['fas', 'xmark']"
            class="tw-bg-status-red-500 tw-rounded-full tw-p-2 tw-w-auto"
          />
        </div>
        <ErrorBoundary>
          <div class="tw-h-0" ref="ptr"></div>
          <MainComponent></MainComponent>
        </ErrorBoundary>
        <DXFooter class="tw-mt-auto" />
      </div>
    </div>
    <AddDeviceGlobalModal />
    <GuidedTourFullscreen />
  </div>
</template>

<script setup lang="ts">
import Navigation from "./navigation/Navigation.vue";
import GuidedTourFullscreen from "./GuidedTourFullscreen.vue";
import MainComponent from "./DXMain.vue";
import DXFooter from "./DXFooter.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Ref, computed, ref, watch } from "vue";
import {
  NavigationToplevelItem,
  NTIBasic,
  NTINested,
  NTIDevices,
} from "./navigation/NavigationUtil";
import {
  useDeviceStore,
  useGlobalConfigsStore,
  useUserConfigStore,
  useUISettingsStore,
} from "@/storeModules";
import AddDeviceGlobalModal from "./AddDeviceGlobalModal.vue";
import DXHeader from "./DXHeader.vue";
import ShrinksWithNavBar from "./navigation/ShrinksWithNavBar.vue";
import ErrorBoundary from "../library/ErrorBoundary/ErrorBoundary.vue";
import { DXDevice } from "@/types";
import { useIsMobile } from "@/utils/useIsMobile";

const { isReactNative } = useIsMobile();
const uiSettingsStore = useUISettingsStore();

const ptr = ref<HTMLElement | null>(null);
const touch = ref({
  start: 0,
  distance: 0,
  ptr: false,
  touchTimeStart: 0,
  touchTimeEnd: 0,
  lastUsed: 0,
  pending: false,
  success: false,
  error: false,
});

const deviceStore = useDeviceStore();
const userStore = useUserConfigStore();
const globalStore = useGlobalConfigsStore();

const devices = computed(() => {
  return deviceStore.getDevicesGroupByTags;
});

const alarms = computed(() => {
  return deviceStore.alarms;
});

const handleTouch = async (e: TouchEvent, type: "start" | "move" | "end") => {
  // This makes sure that PTR is disabled on mobile browsers.
  if (!isReactNative) {
    return;
  }
  if (type === "start") {
    touch.value = {
      ...touch.value,
      start: e.touches[0].pageY || 0,
      distance: 0,
      ptr: false,
      touchTimeStart: Date.now(),
      touchTimeEnd: 0,
      lastUsed: 0,
    };
  } else if (type === "move") {
    touch.value = {
      ...touch.value,
      start: touch.value.start,
      distance: e.touches[0].pageY - touch.value.start,
      ptr: false,
      touchTimeStart: touch.value.touchTimeStart,
      touchTimeEnd: Date.now(),
      lastUsed: 0,
    };

    const duration = touch.value.touchTimeEnd - touch.value.touchTimeStart;
    if (
      isVisibleInViewport(ptr.value) &&
      touch.value.distance > 100 &&
      duration > 300
    ) {
      touch.value.ptr = true;
    }
  } else if (type === "end") {
    if (touch.value.pending) {
      return;
    }
    const duration = touch.value.touchTimeEnd - touch.value.touchTimeStart;
    const refreshDuration = Date.now() - touch.value.lastUsed;
    if (refreshDuration < 1000) {
      return;
    }
    if (
      isVisibleInViewport(ptr.value) &&
      touch.value.distance >= 200 &&
      duration > 300
    ) {
      touch.value.pending = true;

      try {
        uiSettingsStore.haveFullRefresh = true;
        const response = true;
        if (response) {
          console.warn("resp", response);
          touch.value = {
            ...touch.value,
            start: 0,
            ptr: false,
            touchTimeStart: 0,
            touchTimeEnd: 0,
            lastUsed: Date.now(),
            pending: false,
            success: true,
          };
          setTimeout(() => {
            touch.value.success = false;
            touch.value.distance = 0;
          }, 1000);
        }
      } catch (error) {
        touch.value = {
          ...touch.value,
          start: 0,
          ptr: false,
          touchTimeStart: 0,
          touchTimeEnd: 0,
          lastUsed: 0,
          pending: false,
          error: true,
          success: false,
        };
        setTimeout(() => {
          touch.value.error = false;
          touch.value.distance = 0;
        }, 1000);
      }
    } else {
      touch.value = {
        ...touch.value,
        start: 0,
        distance: 0,
        ptr: false,
        touchTimeStart: 0,
        touchTimeEnd: 0,
        lastUsed: 0,
      };
    }
  }
};

const issues = computed(() => deviceStore.issues);
// construct our navigation structure based on user access:
const navBarItems: Ref<NavigationToplevelItem[]> = ref([]);
navBarItems.value.push(
  new NTIBasic("navi.item_overview", "fa-gauge", "Dashboard")
);
const devicesIndex =
  navBarItems.value.push(
    new NTIDevices(devices.value, alarms.value, issues.value)
  ) - 1;
navBarItems.value.push(
  new NTIBasic("navi.item_history", "fa-chart-line", "Value History")
);

if (
  userStore.userData.accessRights &&
  userStore.userData.accessRights["access-advanced-reporting"]
) {
  navBarItems.value.push(
    new NTINested(
      "navi.item_reports",
      "fa-nav-report",
      [
        {
          i18nkey: "reports.module_label_manage_reports",
          targetRouteName: "ManageDeviceReport",
        },
        {
          i18nkey: "reports.module_label_download_reports",
          targetRouteName: "DownloadReports",
        },
      ],
      "ReportsOnboarding"
    )
  );
}

if (
  userStore.userData.accessRights &&
  userStore.userData.accessRights["access-inventory-management"]
) {
  navBarItems.value.push(
    new NTINested(
      "navi.item_inventory",
      "fa-glass-water-droplet",
      [
        {
          i18nkey: "im.submenu_label_dashboard",
          targetRouteName: "IMDashboard",
        },
        { i18nkey: "im.submenu_label_inventory", targetRouteName: "IMList" },
        { i18nkey: "im.submenu_label_map", targetRouteName: "IMMap" },
        { i18nkey: "im.submenu_label_report", targetRouteName: "Reports" },
      ],
      "IM"
    )
  );
}

const isVisibleInViewport = (element: HTMLElement | null) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

watch([devices, alarms, issues], () => {
  navBarItems.value[devicesIndex] = new NTIDevices(
    devices.value ?? new Map<string, DXDevice[]>(),
    alarms.value ?? [],
    issues.value ?? {}
  );
});
</script>
