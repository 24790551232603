<template>
  <div class="tw-text-base">
    <BaseLabel
      :inputId="inputId!"
      :label="label || ''"
      :labelAlignment="labelAlignment"
      :additionalData="additionalData"
      :secondaryLabel="secondaryLabel"
    >
      <datalist id="suggestions">
        <option
          v-for="suggestion in autocompleteList"
          :key="suggestion"
          :value="suggestion"
        />
      </datalist>
      <input
        ref="inputRef"
        :class="`${disabled ? 'tw-opacity-50' : ''}  tw-input-base`"
        :id="inputId"
        list="suggestions"
        type="text"
        :disabled="disabled"
        :placeholder="placeholder"
        :required="required"
        :data-test="dataTest"
        v-model="textInput"
        :isValid="isValid"
        autocomplete="off"
      />
    </BaseLabel>
  </div>
</template>

<script setup lang="ts">
import { PropType, onMounted, ref, watch } from "vue";
import { BaseLabelProps } from "../BaseLabel/BaseLabelUtils";
import BaseLabel from "../BaseLabel/BaseLabel.vue";

const inputRef = ref<null | HTMLInputElement>(null);

const props = defineProps({
  disabled: { type: Boolean, required: false },
  placeholder: { type: String, required: false },
  modelValue: { type: String, required: true },
  required: { type: Boolean, required: false },
  value: { type: String, required: false },
  isValid: { type: Boolean, required: false, default: undefined },
  autocompleteList: { type: Array as PropType<string[]>, required: true },
  onlyValuesFromList: { type: Boolean, default: false },
  dataTest: { type: String, default: "" },
  maxResults: { type: Number, default: 5 },
  focusOnMounted: { type: Boolean, default: false },
  ...BaseLabelProps,
});

const emit = defineEmits(["update:modelValue"]);

const textInput = ref(props.modelValue);

// // watcher to be able to limit input to list items
watch(textInput, () => {
  if (!props.onlyValuesFromList) {
    emit("update:modelValue", textInput.value);
  } else {
    if (props.autocompleteList.includes(textInput.value)) {
      emit("update:modelValue");
    }
  }
});

onMounted(() => {
  if (props.focusOnMounted && inputRef.value != null) {
    inputRef.value.focus();
    inputRef.value.select();
  }
});
</script>
